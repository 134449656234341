import React from 'react'
import Form from '../components/Form'
import Wrapper from '../hoc/Wrapper'

const Contatti = () => {
    return (
        <div className='drop-shadow-2xl'>
            <div className='flex flex-col justify-center items-center'>
                <h1 className="mb-12 text-6xl text-center font-bold text-transparent  drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] tracking-wide bg-clip-text bg-gradient-to-b from-green-700 to-green-400 inline-block">Contatti</h1>
                <div className='flex items-center xl:flex-row flex-col gap-4'>
                    <div className='text-center flex flex-col gap-1 items-center'>
                        <h2 className='text-xl font-semibold text-center'>(Vicenza - Maddalene)</h2>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2793.0912997589826!2d11.495886326849082!3d45.568602571075886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477f341258b751ef%3A0xe9f09b2a7b5ba8ce!2s36100%20Maddalene%20VI!5e0!3m2!1sit!2sit!4v1715347759774!5m2!1sit!2sit"  className='rounded-xl md:w-[600px] w-full md:h-[550px] h-60' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                <Form />
                </div>
            </div>
        </div>
    )
}

export default Wrapper(Contatti, "contact")